<template>
  <div class="main-templates" >
    <b-row>
      <swiper
          class="swiper-centered-slides-2 p-1"
          :options="swiperOptions"
          @click="slideChange"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
        
          <swiper-slide
          :index="index"
         :key="index"   v-for="(data, index) in inApp"
         
            
            class="rounded swiper-shadow py-1 px-3 d-flex"
          >
            <div :index="index" class="swiper-text">
              {{ data.templateData.name }}
            </div>
          </swiper-slide>
        </swiper>
    </b-row>
      <b-row>
        <b-col :lg="3">
          <contilt-inputs
            @on-input-changed="onInputChanged"
            ref="contilt-inputs"
            :sampleinputs="sampleinputs"
          ></contilt-inputs>
    <b-button 
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      type="submit"
      variant="primary"
      class="mr-1"
      @click="saveContiltDataChanges"
      block
    >
      <b-spinner v-if="saveContiltDataChangesLoading" small />
      Save changes
    </b-button>
        </b-col>
        <b-col lg="9">

          <div class="in-app-class" :key="index" v-for="(data, index) in inApp" v-show="selectedInApp.url == data.url">
    <b-button 
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      type="submit"
      variant="primary"
      class="mr-1 contilt-advanced"
      v-if="data.advancedUrl"
      @click="openAdvanced(data.advancedUrl)"
      
    >
      Advanced
    </b-button>
            <iframe :src="data.url + '?useSocket=true'">
            </iframe>

          </div>
        </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "@axios";
import ContiltOutput from "./outputsfactory/outputsfactory.vue";
import contiltInputs from "./inputsFactory/inputsFactory.vue";
import AddReview from "../../../contilt/addReview/addreview.vue";
import GenerateError from "../../../contilt/generateError/generateerror.vue";
import { BRow, BCol, BImg, BOverlay, BButton, BSpinner } from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import SocketioService from "./socketio.service";
import { onBeforeUnmount } from "@vue/composition-api";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from "vue-ripple-directive";
export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BImg,
    BOverlay,
    ContiltOutput,
    contiltInputs,
    AddReview,
    GenerateError,
        Swiper,
    SwiperSlide,
    BButton, BSpinner
  },
  mounted(){
    let self = this;
    this.$nextTick(() => {
        window.addEventListener('message', function(event) {
          if(event && event.data && event.data && event.data.socketId){
            self.inAppSockets.add(event.data.socketId)
          }
        });
    })
  },
  setup() {
    onBeforeUnmount(() => {
      SocketioService.disconnect();
    });
  },
  methods: {
    openAdvanced(url){
      window.open(url, "_blank");
    },
    saveContiltDataChanges(){
      this.saveContiltDataChangesLoading = true;
      const contiltData = this.selectedInApp.contiltData;
      axios.patch(`visualTemplates/contiltData/${contiltData.id}`, {data: contiltData.data}).then(() => {
        this.saveContiltDataChangesLoading = false
        this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Successfully saved",
              icon: "CoffeeIcon",
              variant: "success",
              text: `Successfully saved`,
            },
          });
      }).catch(err => {
        this.saveContiltDataChangesLoading= false;
        this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error happened",
              icon: "AlertTriangleIcon",
              variant: "light-danger",
              text: `${err.response.data.error || err.message}`,
            },
          });
      })
    },
    slideChange(data){
      this.selectedInApp = this.inApp[parseInt(data.target.getAttribute("index"))]  
    },
    onInputChanged(){
      const resultsData = this.$refs["contilt-inputs"].resultsData;
    
      const contiltData = this.selectedInApp.contiltData.data;
      Object.keys(contiltData).forEach(k => {
        if(resultsData[k] ){
          if(!contiltData[k].value){
            contiltData[k] = {value: ""}
          }
          contiltData[k].value = resultsData[k];
        }
      })
 
      this.inApp.forEach(x => {
        x.contiltData.data = contiltData
      })

      SocketioService.updateContiltData({contiltData, inAppSockets: Array.from(this.inAppSockets)});
    }
  },
  data() {
    return {
      saveContiltDataChangesLoading:false,
      inApp: [],
      socketId: null,
      selectedInApp: null,
      swiperOptions: {
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 30,
        slideToClickedSlide: true,
      },
      inAppSockets: new Set()
    };
  },

  computed: {

    sampleinputs(){
      if(!this.selectedInApp){
        return [];
      }
      const contiltData = this.selectedInApp.contiltData.data;
      return this.selectedInApp.templateData.varList.map(x=> {
        return {
          ...x,
          label: x.name,
          "_nocompress": true,
          value: contiltData[x.name] ? contiltData[x.name].value : ""
        }
      });
    }
  },

  created() {
    SocketioService.setupSocketConnection();
    let self = this;
    SocketioService.getSocket().on('connect', () => {
      self.socketId = SocketioService.getSocket().id;
    })
    axios.get(`visualTemplates/namespace/${this.$route.params.id}`).then(res => {
      this.inApp = res.data;
      this.selectedInApp = this.inApp[0]
    })
    
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
    this.$store.commit("templatesStore/setSelectedTemplate", {});
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/swiper.scss";
.in-app-class {
    width: 100%;
    height: 800px;
  }
  .in-app-class iframe {
      width: 100%;
      height: 100%;
  }
  .contilt-advanced {
    position: absolute !important;
  }
</style>