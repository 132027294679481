<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">Under Maintenance 🛠</h2>

        <p class="mb-3">
          Sorry for the inconvenience but we're performing some maintenance at
          the moment
        </p>

        <b-form
          inline
          class="justify-content-center row m-0 mb-2"
          @submit.prevent
        >
          <b-button
            variant="primary"
            class="mb-1 btn-sm-block"
            type="submit"
            @click="buttonClick"
          >
            Try Again
          </b-button>
        </b-form>

        <!-- img -->
        <b-img fluid :src="imgUrl" alt="Under maintenance page" />
      </div>
    </div>
  </div>
</template>

<script>
import { BFormInput, BButton, BForm, BImg } from "bootstrap-vue";
import store from "@/store/index";

export default {
  components: {
    BFormInput,
    BButton,
    BForm,
    BImg,
  },
  props: ["buttonClick"],
  data() {
    return {
      downImg: require("@/assets/images/pages/under-maintenance.svg"),
      email: "",
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.downImg = require("@/assets/images/pages/under-maintenance-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
